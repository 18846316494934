import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Search from "./Search";
import { ReactComponent as IconCart3 } from "bootstrap-icons/icons/cart3.svg";
import { ReactComponent as IconPersonBadgeFill } from "bootstrap-icons/icons/person-badge-fill.svg";
import { ReactComponent as IconStarFill } from "bootstrap-icons/icons/star-fill.svg";
import { ReactComponent as IconListCheck } from "bootstrap-icons/icons/list-check.svg";
import { ReactComponent as IconDoorClosedFill } from "bootstrap-icons/icons/door-closed-fill.svg";
import { ReactComponent as IconHeartFill } from "bootstrap-icons/icons/heart-fill.svg";
import { ReactComponent as IconBellFill } from "bootstrap-icons/icons/bell-fill.svg";
import { ReactComponent as IconInfoCircleFill } from "bootstrap-icons/icons/info-circle-fill.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Loader from "./loader/MenuLoader";
import Marquee from "react-fast-marquee";

const TopMenu = (props) => {
  const [user, setUser] = useState('');
  const [login, setlogin] = useState(false);
  const [category, setCategory] = useState([]);
  const [gst, setGst] = useState(true);

  const logout = () => {
    props.logOut()
  }

  const getProducts = async () => {
    const response = await fetch('https://api.khelwholesale.in/Example/menu');
    var data = await response.json();
    if (data.status) {
      setCategory(data.Data);
    } else {
      setCategory([])
    }
  }

  useEffect(() => {
    getProducts()
  }, [])

  useEffect(() => {
    if (props.user.length) {
      setUser(props.user);
      setlogin(true);
    } else {
      setlogin(false)
    }
  }, [props.user])

  return (
    <React.Fragment>
      <nav className="navbar sticky-top navbar-expand-lg d-sm-block d-lg-none p-0 bg-light w-100">
        <div className="float-left ml-3 p-1">
          {login ?
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-secondary rounded-circle border mr-3 dropdown-toggle1"
                data-toggle="dropdown"
                aria-expanded="false"
                aria-label="Profile"
              >
                <FontAwesomeIcon icon={faUser} className="text-light" />
              </button>
              <ul className="dropdown-menu dropdown-menu-left">
                <li>
                  <Link className="dropdown-item" to="/account/orders">
                    <IconListCheck className="text-primary" /> Orders
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" onClick={() => logout()}>
                    <IconDoorClosedFill className="text-danger" /> Logout
                  </Link>
                </li>
              </ul>
            </div>
            :
            <Link to="/account/signin">
              <button
                type="button"
                className="btn btn-secondary rounded-circle border float-left"
                aria-label="Profile"
              >
                {/* <i class="fas fa-user"></i> */}
                <FontAwesomeIcon icon={faUser} className="text-light" />
              </button>
              {/* <button className="btn btn-primary float-right mr-1">Login</button> */}
            </Link>
          }
        </div>
        <div className="float-right mr-3 p-1">
          <Link to="/cart" className="btn btn-warning">
            <span className="bg-danger text-light rounded mr-1 px-1">
              {props.items}
            </span>
            <IconCart3 className="i-va" />
          </Link>
        </div>
      </nav>

      <nav className={`navbar navbar-expand-lg d-none d-lg-block p-0 w-100 bg-white`}>
        <div style={{ zIndex: -1 }} className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
            {category.length ?
              <Marquee
                pauseOnHover={true}
              >
                <ul className="navbar-nav my-1 w-100">
                  {category.map((element, index) => {
                    return (
                      <li className="nav-item text-center mx-auto" key={index}>
                        <Link className="nav-link text-light" to={{ pathname: "/keyword/" + element.keywordSearchText }} data-toggle="collapse" data-target=".navbar-collapse.show">
                          {props.menu && <img className="mr-auto ml-auto d-none d-lg-block"
                            src={element.keywordIcon} />
                          }
                          <h4 className="my-auto text-dark">{element.keywordName}</h4>
                        </Link>
                      </li>
                    )
                  })
                  }
                </ul>
              </Marquee>
              :
              <ul className="navbar-nav">
                <li className="nav-item text-center">
                  {/* <h1 className="text-light">Loading...</h1> */}
                </li>
              </ul>
            }
          </div>
        </div>
        {props.menu === false && <hr className="my-0" />}
      </nav>
    </React.Fragment>
  );
};

export default TopMenu;
