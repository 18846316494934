import React from 'react'
import ContentLoader from "react-content-loader"

function Loader(props) {
    return (
            <ContentLoader
                className='mx-3 my-3 ml-lg-2 mr-lg-2 bg-danger'
                speed={2}
                height={80}
                width={75}
                // viewBox="0 0 400 160"
                foregroundColor="#dc3545"
                {...props}
            >
                <rect x="2" y="2" rx="0" ry="0" width="70" height="60" />
                {/* <rect x="0" y="0" rx="3" ry="3" width="88" height="6" /> */}
                {/* <rect x="48" y="26" rx="3" ry="3" width="52" height="6" /> */}
                {/* <rect x="0" y="56" rx="3" ry="3" width="410" height="6" /> */}
                <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                {/* <rect x="0" y="88" rx="3" ry="3" width="178" height="6" /> */}
                {/* <circle cx="20" cy="20" r="20" /> */}
            </ContentLoader>
    )
}

export default Loader
