import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import TopMenu from "./components/TopMenu";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Axios from "axios";
import "./App.min.css";
import "./App.css";
import { useCookies } from 'react-cookie';
// const Header = lazy(() => import("./components/Header"));
// const TopMenu = lazy(() => import("./components/TopMenu"));
// import ProductListView from "./views/product/List";
const Abcd = import('./Ayush');
const HomeView = lazy(() => import("./views/Home"));
const Demo = lazy(() => import("./demo"));
const SignInView = lazy(() => import("./views/account/SignIn"));
const SignUpView = lazy(() => import("./views/account/SignUp"));
const ForgotPasswordView = lazy(() => import("./views/account/ForgotPassword"));
const OrdersView = lazy(() => import("./views/account/Orders"));
const RegisterView = lazy(() => import("./views/account/Register"));
const NotificationView = lazy(() => import("./views/account/Notification"));
const MyProfileView = lazy(() => import("./views/account/MyProfile"));
const UserData = lazy(() => import("./views/account/UserData"));
const CategoryList = lazy(() => import("./views/product/CategoryList"));
const KeywordListing = lazy(() => import("./views/product/KeywordListing"));
const ProductList = lazy(() => import("./views/product/ProductList"));
const ViewBrand = lazy(() => import("./views/product/ViewBrand"));
const BrandProducts = lazy(() => import("./views/product/BrandProducts"));
const BrandProduct = lazy(() => import("./views/product/BrandProduct"));
const wholesaler = lazy(() => import('./views/pages/wholesale'));
const BrandProductDetails = lazy(() => import("./views/product/BrandProductDetails"));
const BrandList = lazy(() => import("./views/product/BrandList"));
const CategoryView = lazy(() => import("./views/product/List"));
const CustomList = lazy(() => import("./views/product/CustomList"));
const KeywordListView = lazy(() => import("./views/product/KeywordList"));
const ProductDetailView = lazy(() => import("./components/card/Detail"));
const StarZoneView = lazy(() => import("./views/product/StarZone"));
const CartView = lazy(() => import("./views/cart/Cart"));
const CheckoutView = lazy(() => import("./views/cart/Checkout"));
const DocumentationView = lazy(() => import("./views/Documentation"));
const NotFoundView = lazy(() => import("./views/pages/404"));
const InternalServerErrorView = lazy(() => import("./views/pages/500"));
const ContactUsView = lazy(() => import("./views/pages/ContactUs"));
const SupportView = lazy(() => import("./views/pages/Support"));
const BlogView = lazy(() => import("./views/blog/Blog"));
const BlogDetailView = lazy(() => import("./views/blog/Detail"));
const ReturnPolicy = lazy(() => import("./components/Footer/ReturnPolicy"));
const TermsOfUse = lazy(() => import("./components/Footer/TermsOfUse"));
const Refund = lazy(() => import("./components/Footer/Refund"));
const Privacy = lazy(() => import("./components/Footer/Privacy"));
const Shipping = lazy(() => import("./components/Footer/Shipping"));
const Dispute = lazy(() => import('./components/Footer/Dispute'));
const About = lazy(() => import('./components/Footer/About'));
const SportProduct = lazy(() => import('./views/product/SportProduct'));
const Payment = lazy(() => import('./views/payment/Payment'));

const getuser = () => {
  let Cart = JSON.parse(localStorage.getItem('User'));
  if (Cart) {
    return [Cart];
  }
  else {
    return [];
  }
}

const getLocalItems = () => {
  let Cart = JSON.parse(localStorage.getItem('Cart'));
  if (Cart && Cart.length) {
    return Cart.length;
  } else {
    return 0;
  }
}


const App = () => {
  const [user, setuser] = useState(getuser);
  const [response, setresponse] = useState('');
  const [login, setLogin] = useState('');
  const [menu, setMenu] = useState(true);
  const [items, setItems] = useState(getLocalItems);
  const history = useHistory();

  const getName = (values) => {
    let Data = JSON.stringify(values);
    Axios.post('https://api.khelwholesale.in/Example/login', Data)
      .then(response => {
        console.log(response.data)
        setresponse(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const unsetMenu = (val) => {
    if (val) {
      setMenu(false)
    } else {
      setMenu(true)
    }
  }
  const setitems = (num) => {
    setItems(num)
  }
  const logout = () => {
    localStorage.removeItem('User');
    setuser([]);
  }
  const signUp = (data) => {
    // console.log(data)
    let Data = {
      id: data.userID,
      // gst: data.gst
    }
    setuser([Data])
    localStorage.setItem('User', JSON.stringify(Data))
  }
  useEffect(() => {
    if (response.Data) {
      setuser([response.Data[0]])
      let data = {
        id: response.Data[0].userID,
        // gst: response.Data[0].gst
      }
      localStorage.setItem('User', JSON.stringify(data))
    } else {
      if (response !== "") {
        alert("incorrect");
      }
    }
  }, [response])

  useEffect(() => {
    if (user.length) {
      setLogin(true);
    } else {
      setLogin(false)
    }
  }, [user])

  return (
    <BrowserRouter>
      <React.Fragment>
        <Header logOut={logout} user={user} items={items} />
        <TopMenu logOut={logout} user={user} menu={menu} items={items} />
        <Suspense
          fallback={
            <div className="text-center mt-3" style={{ height: '100vh' }}>
              <div className="d-lg-none">
                <div className="spinner-border text-primary" style={{ zIndex: '9999999999', marginTop: '45%' }} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              <div className="d-none d-lg-block">
                <div className="spinner-border text-primary" style={{ zIndex: '9999999999', marginTop: '15%' }} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          }
        >
          <Switch>
            <Route exact path="/" component={() => <HomeView login={login} user={user} setMenu={unsetMenu} setItem={setitems} />} />
            <Route exact path="/demo/" component={Demo} />
            <Route exact path="/account/signin" component={() => <SignInView getData={getName} login={login} />} />
            <Route exact path="/account/signup" component={() => <SignUpView signUp={signUp} login={login} />} />
            <Route
              exact
              path="/account/forgotpassword"
              component={ForgotPasswordView}
            />
            <Route exact path="/account/profile" component={MyProfileView} />
            <Route exact path="/account/orders" component={() => <OrdersView user={user} />} />
            <Route exact path="/account/register" component={() => <RegisterView signUp={signUp} user={user} />} />
            <Route
              exact
              path="/account/notification"
              component={NotificationView}
            />
            <Route exact path="/category" component={() => <CategoryList setMenu={unsetMenu} />} />
            <Route exact path="/keyword" component={() => <KeywordListing setMenu={unsetMenu} />} />
            <Route exact path="/brand" component={() => <BrandList setMenu={unsetMenu} />} />
            <Route exact path="/products" component={() => <ProductList user={user} setMenu={unsetMenu} setItem={setitems} />} />
            <Route exact path="/brandproducts/:subcategory" component={() => <BrandProducts user={user} setMenu={unsetMenu} setItem={setitems} />} />
            <Route exact path="/brandproduct/:brand" component={() => <BrandProduct user={user} setMenu={unsetMenu} setItem={setitems} />} />
            <Route exact path="/sportproduct/:sport" component={() => <SportProduct setMenu={unsetMenu} setItem={setitems} />} />
            <Route exact path="/customList/:list" component={() => <CustomList setMenu={unsetMenu} />} />
            <Route exact path="/subcategory/:category" component={() => <CategoryView setMenu={unsetMenu} />} />
            <Route exact path="/keyword/:keyword" component={() => <KeywordListView setMenu={unsetMenu} />} />
            <Route exact path="/product/detail/:product" component={() => <BrandProductDetails setMenu={unsetMenu} setItem={setitems} />} />
            <Route exact path="/userData/" component={() => <UserData user={user} />} />
            <Route exact path="/payment/" component={() => <Payment setMenu={unsetMenu} user={user} />} />
            <Route exact path="/star/zone" component={StarZoneView} />
            <Route exact path="/cart" component={() => <CartView user={user} setMenu={unsetMenu} setItem={setitems} />} />
            <Route exact path="/wholesaler" component={wholesaler} />
            <Route exact path="/checkout" component={() => <CheckoutView user={user} setMenu={unsetMenu} />} />
            <Route exact path="/documentation" component={DocumentationView} />
            <Route exact path="/contact-us" component={ContactUsView} />
            <Route exact path="/support" component={SupportView} />
            <Route exact path="/blog" component={BlogView} />
            <Route exact path="/blog/detail" component={BlogDetailView} />
            <Route exact path="/returnpolicy" component={ReturnPolicy} />
            <Route exact path="/termsandconditions" component={TermsOfUse} />
            <Route exact path="/faq" component={Refund} />
            <Route exact path="/privacypolicy" component={Privacy} />
            <Route exact path="/shippingpolicy" component={Shipping} />
            <Route exact path="/deliverypolicy" component={Dispute} />
            <Route exact path="/about" component={About} />
            <Route exact path="/500" component={InternalServerErrorView} />
            <Route component={NotFoundView} />
          </Switch>
        </Suspense>
        <Footer />
      </React.Fragment>
    </BrowserRouter>
  );
}

export default App;