import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconTelephone } from "bootstrap-icons/icons/telephone.svg";
import { ReactComponent as IconEnvelope } from "bootstrap-icons/icons/envelope.svg";
import { ReactComponent as IconBriefcase } from "bootstrap-icons/icons/briefcase.svg";
import { ReactComponent as IconBadgeAd } from "bootstrap-icons/icons/badge-ad.svg";
import { ReactComponent as IconGift } from "bootstrap-icons/icons/gift.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faFacebookF,
  faInstagram,
  faWhatsapp,
  faApple,
  faWindows,
  faAndroid,
} from "@fortawesome/free-brands-svg-icons";


const Footer = (props) => {
  const [whatsaap, setWhatsaap] = useState('');
  const [facebook, setFacebook] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [instagram, setInstagram] = useState('');

  console.log(props);

  const getSocial = async () => {
    const response = await fetch('https://api.khelwholesale.in/Example/SocialMedia/');
    var data = await response.json();
    // console.log(data)
    setFacebook(data[0].facebook);
    setLinkedIn(data[0].linkedin);
    setWhatsaap(data[0].whatsapp);
    setInstagram(data[0].instagram);
  }

  useEffect(() => {
    getSocial()
  }, [])


  return (
    <React.Fragment>
      <footer>
        <div className="container-fluid bg-transparent">
          <div className="row text-center">
            <a href="#top" className="h6" style={{ textDecoration: "none" }}>Back to top</a>
          </div>
          <div className="row">
            <div className="col-md-6 pt-2 bg-white">
              <span className="h5 mr-3">
                We accept payments via:
              </span>
              <img
                src="../../images/payment/american_express.webp"
                width="32"
                alt="American Express"
                className="mx-2"
              />
              <img
                src="../../images/payment/maestro.webp"
                width="32"
                alt="Maestro"
                className="mr-2"
              />
              <img
                src="../../images/payment/netbanking.webp"
                width="32"
                alt="Net Banking"
                className="mr-2"
              />
              <img
                src="../../images/payment/paypal.webp"
                width="32"
                alt="Paypal"
                className="mr-2"
              />
              <img
                src="../../images/payment/rupay.webp"
                width="32"
                alt="Rupay"
                className="mr-2"
              />
              <img
                src="../../images/payment/upi.webp"
                width="32"
                alt="UPI"
                className="mr-2"
              />
              <img
                src="../../images/payment/visa.webp"
                width="32"
                alt="Visa"
                className="mr-2"
              />
            </div>
            <div className="col-lg-4 col-md-6 pt-3 text-center h5">
              Get Connected with us on social media
            </div>
            <div className="col-lg-2 col-md-6 pt-3 text-center h4">
              {/* <Link to="/" title="Apple">
                <FontAwesomeIcon icon={faApple} className="mr-3" style={{ color: '#555555' }} />
              </Link>
              <Link to="/" title="Windows">
                <FontAwesomeIcon icon={faWindows} className="mr-3" style={{ color: '#ffbb00	' }} />
              </Link>
              <Link to="/" title="Android">
                <FontAwesomeIcon icon={faAndroid} className="mr-3" style={{ color: '#3DDC84' }} />
              </Link>
              | */}
              <a href={'https://api.whatsapp.com/send/?phone=%2B91' + whatsaap} target={'_blank'} title="Whatsapp">
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className="ml-3 mr-3"
                  style={{ color: '#4FCE5D' }}
                />
              </a>
              <a href={facebook} target={'_blank'} title="Facebook">
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="mr-3"
                  style={{ color: '#4267B2' }}
                />
              </a>
              <a href={instagram} target={'_blank'} title="Instagram">
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="mr-3"
                  style={{ color: '#e95950' }}
                />
              </a>
              <a href={linkedIn} target={'_blank'} title="linkedIn">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="mr-3"
                  style={{ color: '#0072b1' }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-dark text-white d-none d-lg-block">
          <div className="row ">
            <div className="col-md-4 py-3">
              <div className="h6">KhelWholeSale</div>
              <hr />
              <p>
                KhelWholeSale is a unique E-Commerce site which provide digital platform for manufacturers,
                wholesellers and retailers along with consumers. Our aim is to provide best opportunities
                for manufacturers to sell their products to wholesellers on wholesale basis. Khelwholesale is
                introduced by <b>Bajrang Traders Meerut</b> <Link to='/about'>Read more..</Link>
              </p>
            </div>
            <div className="col-md-4 py-3">
              <div className="h6">Policies</div>
              <hr />
              <ul className="list-group list-group-flush">
                <li className="list-group-item bg-dark text-white border-light">
                  <Link
                    to="/returnpolicy"
                    className="text-decoration-none text-white stretched-link"
                  >
                    Return & Cancellation Policy
                  </Link>
                </li>
                <li className="list-group-item bg-dark text-white border-light">
                  <Link
                    to="/privacypolicy"
                    className="text-decoration-none text-white stretched-link"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="list-group-item bg-dark text-white border-light">
                  <Link
                    to="/deliverypolicy"
                    className="text-decoration-none text-white stretched-link"
                  >
                    Delivery Policy
                  </Link>
                </li>
                <li className="list-group-item bg-dark text-white border-light">
                  <Link
                    to="/faq"
                    className="text-decoration-none text-white stretched-link"
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li className="list-group-item bg-dark text-white border-light">
                  <Link
                    to="/faq"
                    className="text-decoration-none text-white stretched-link"
                  >
                    FAQ's
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4 py-3">
              <div className="h6">Address</div>
              <hr />
              <address>
                <strong>Meerut UP 250001</strong>
                <br />            <div class="col-md-1 pt-2"></div>
            <div class="col-md-1 pt-2"></div>

                Phone : (+91) 6395062516
              </address>
              {/* <div className="h6">Customer Care</div>
              <hr />
              <IconTelephone /> +1800 000 0000 */}
              <br />
              <IconEnvelope /> info@KhelWholeSale.com
              <br />
              <IconEnvelope /> info@KhelWholeSale.in
            </div>
          </div>
        </div>
        <div className="container-fluid bg-secondary text-white text-center">
          <div className="row">
            <div className="col-md-3 py-2">
              © 2021 - {new Date().getFullYear()} KhelWholeSale.in
            </div>
            <div className="col-md-6"></div>
            <div class="col-md-3 py-2">
              Developed by : <a className="text-white font-weight-bold" target="blank" href="http://rabbitinfotech.com/">Rabbit Infotech LLP</a>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
export default Footer;
