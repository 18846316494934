import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import Search from "./Search";
import { useHistory } from "react-router-dom";
import { useuser } from "react-cookie";
import { ReactComponent as IconCart3 } from "bootstrap-icons/icons/cart3.svg";
import { ReactComponent as IconPersonBadgeFill } from "bootstrap-icons/icons/person-badge-fill.svg";
import { ReactComponent as IconStarFill } from "bootstrap-icons/icons/star-fill.svg";
import { ReactComponent as IconListCheck } from "bootstrap-icons/icons/list-check.svg";
import { ReactComponent as IconDoorClosedFill } from "bootstrap-icons/icons/door-closed-fill.svg";
import { ReactComponent as IconHeartFill } from "bootstrap-icons/icons/heart-fill.svg";
import { ReactComponent as IconBellFill } from "bootstrap-icons/icons/bell-fill.svg";
import { ReactComponent as IconInfoCircleFill } from "bootstrap-icons/icons/info-circle-fill.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Header = (props) => {
  const [user, setUser] = useState('');
  const [login, setlogin] = useState(false);
  const history = useHistory();
  const [gst, setGst] = useState(true);
  const [whatsaap, setWhatsaap] = useState('');

  const logout = () => {
    props.logOut()
  }

  const getSocial = async () => {
    const response = await fetch('https://api.khelwholesale.in/Example/SocialMedia/');
    var data = await response.json();
    // console.log(data)
    setWhatsaap(data[0].whatsapp);
  }

  useEffect(() => {
    getSocial()
  }, [])

  useEffect(() => {
    if (props.user.length) {
      setUser(props.user);
      setlogin(true);
    } else {
      setlogin(false)
    }
  }, [props.user])

  const myStyle = {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 90,
    height: 'auto',
  }

  return (
    <React.Fragment>
      <header className="py-4" id="top-nav">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 text-center">
              <Link to="/">
                <img
                  className="ml-auto mr-auto"
                  alt="logo"
                  src="../../images/logo.png"
                  style={myStyle}
                />
              </Link>
            </div>
            <div className="col-lg-7">
              <Search />
            </div>
            <div className="col-lg-2 d-none d-lg-block">
              <div className="position-relative d-inline mr-3">
                <a href={'https://api.whatsapp.com/send/?phone=%2B91' + whatsaap} className="btn btn-success mr-2" target={'_blank'} title="Whatsapp">
                  <FontAwesomeIcon icon={faWhatsapp} className="text-light" />
                </a>
                {login ?
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-secondary rounded-circle border mr-3 dropdown-toggle1"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      aria-label="Profile"
                    >
                      <FontAwesomeIcon icon={faUser} className="text-light" />
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                      {/* <li>
                        <Link className="dropdown-item" to="/account/profile">
                          <IconPersonBadgeFill /> My Profile
                        </Link>
                      </li> */}
                      {/* <li>
                        {gst ?
                          <Link className="dropdown-item">
                            <IconStarFill className="text-warning" /> Switch to Retail
                          </Link>
                          :
                          <Link className="dropdown-item">
                            <IconStarFill className="text-warning" /> Switch to Wholesale
                          </Link>
                        }
                      </li> */}
                      <li>
                        <Link className="dropdown-item" to="/account/orders">
                          <IconListCheck className="text-primary" /> Orders
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" onClick={() => logout()}>
                          <IconDoorClosedFill className="text-danger" /> Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                  :
                  <Link to="/account/signin">
                    <button className="btn btn-primary mr-2">Login</button>
                  </Link>
                }
                <Link to="/cart" className="btn btn-warning">
                  <IconCart3 className="i-va" />
                  <div className="position-absolute top-0 left-100 translate-middle badge bg-danger rounded-circle">
                    {props.items}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};
export default Header;
