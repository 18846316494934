import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as IconSearch } from "bootstrap-icons/icons/search.svg";
// import SuggestionsList from './SuggestionsList';

const Search = () => {
  const [search, setSearch] = useState('');
  const [suggest, setSuggest] = useState(false)
  const history = useHistory();
  const [suggestions, setSuggestions] = useState();

  const getSuggestions = async () => {
    const response = await fetch('https://api.khelwholesale.in/Example/getKeywords/' + search);
    var data = await response.json();
    // console.log(data.Data);
    if (data.status) {
      setSuggestions(data.Data)
    } else {
      setSuggestions([])
    }
  }

  function goToDetail(pid) {
    alert('hello')
    // history.push('/product/detail/'+pid)
  }

  function abcde() {
    alert('yes')
  }

  useEffect(() => {
    if (search) {
      getSuggestions()
    }
  }, [search])

  const searchGo = (value) => {
    setSearch(value);
  }

  function setsuggest(status) {
    setSuggest(status)
  }

  const submitHandler = (e) => {
    e.preventDefault();
    if (search) {
      history.push('/sportproduct/' + search);
    }
  }

  useEffect(() => {
    console.log(search);
  }, [search])


  return (
    <form
      className="search"
      onSubmit={(e) => submitHandler(e)}
    >
      <div className="input-group">
        <input
          id="search"
          name="search"
          type="text"
          className="form-control"
          autoComplete="off"
          placeholder="Search"
          value={search}
          // onFocus={() => setsuggest(true)}
          list={'encodings'}
          // onBlur={() => setsuggest(false)}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button
          className="btn btn-danger text-white"
          type="submit"
          aria-label="Search"
        >
          <IconSearch />
        </button>
      </div>
      <datalist id="encodings">
        {suggestions &&
          <>
            {suggestions.length ?
              <>
                {
                  suggestions.map((element) => {
                    return (
                      <option value={element.productName}>from {element.subcategoryName}</option>
                    )
                  })
                }
              </>
              :
              <>
                no results found..
              </>
            }
          </>
        }
      </datalist>
    </form>
  );
};
export default Search;
